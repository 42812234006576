import React from "react"
import { graphql, Link } from "gatsby"
import { navigate } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/SEO/seo"
import {
  DividedSection,
  Title,
  Text,
  Badge,
  Button,
} from "gatsby-theme-material-foundry"
import {
  Grid,
  Divider,
  Container,
  Box,
  makeStyles,
  Hidden,
  useMediaQuery,
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary
} from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import EventList, { GenerateTags } from "../components/event-list"
import { EventSvg } from "../components/svgs"
import OverlayImage from "../components/OverlayImage"
import { FilterButtonList } from "../components/filter-button"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import moment from "moment"
require('moment/locale/nb');

moment.locale("nb")


const useStyles = makeStyles(theme => ({
  hovered: {
    backgroundColor: "#f7f7f7",
    cursor: "pointer",
  },
  buttonline: {
    marginTop: "-40px",
  },
  eventsContainer: {
    minHeight: "50vh",
    paddingTop: "30px",
  },
  date: {
    position: "relative",
    bottom: "110px",
    marginBottom: "-80px",
    textAlign: "center",
    color: "white",
    zIndex: 100000,
    "& h6": {
      margin: 0,
    },
  },
  subtitle: {
    fontWeight: 600,
  },
  hero: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "150px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "250px",
      paddingBottom: "100px",
    },
  },
  svg: {
    width: "200px",
  },
}))

function EventsPage(props) {
  const urlCampus =
    props.location.search.split("=")[1] === "Forde"
      ? "Førde"
      : props.location.search.split("=")[1]
  const [events, setEvents] = React.useState(
    props.location.search
      ? props.data.allArrangement.nodes.filter(e => e.campus === urlCampus)
      : props.data.allArrangement.nodes
  )
  const [filter, setFilter] = React.useState({
    campus: props.location.search ? urlCampus : "",
  })
  const [hovered, setHovered] = React.useState(
    props.location.search ? urlCampus : ""
  )
  const onHover = data => setHovered(data)
  const backupEventImg = props.data.eventImg.childImageSharp.fluid
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Layout dark>
      <SEO
        postDescription="Les om kommende arrangement ved HVL Skape"
        postTitle={
          props.location.search
            ? `Kommende Arrangement ved ${urlCampus}`
            : "Kommende Arrangement"
        }
        slug="arrangement"
      />
      <Container maxWidth="md">
        <DividedSection className={classes.hero}>
          <Box>
            <Title variant="h2" primary>
              {filter.campus ? `Arrangement ${ filter.campus === "Stord" ? "på" : "i"} ${filter.campus}` : "Arrangement"}
            </Title>
            <Text black variant="h5" className={classes.subtitle}>
              Les om kommende arrangement ved HVL Skape
            </Text>
          </Box>
          <Hidden xsDown>
            <Container align="center">
              <EventSvg
                fill1={theme.palette.black.main}
                fill2={theme.palette.primary.main}
                fill3={theme.palette.black.main}
                className={classes.svg}
              />
            </Container>
          </Hidden>
        </DividedSection>
      </Container>
      <Box className={classes.buttonline}>
        <Container maxWidth="md" align="center">
        <Box mb={4}>
        {useMediaQuery(theme.breakpoints.up('sm')) ? 
        <FilterButtonList
          filterNames={["", "Bergen", "Haugesund", "Sogndal", "Stord", "Førde"]}
          setEvents={setEvents}
          setFilter={setFilter}
          data={props.data}
          filter={filter}
          type="events"
        />
        : (<ExpansionPanel>
           <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Text black>Filtrer arrangement</Text>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <FilterButtonList
          filterNames={["", "Bergen", "Haugesund", "Sogndal", "Stord", "Førde"]}
          setEvents={setEvents}
          setFilter={setFilter}
          data={props.data}
          filter={filter}
          type="events"
        />
        </ExpansionPanelDetails>
        </ExpansionPanel>)}
        </Box>
        </Container>
      </Box>
      <Container maxWidth="md" className={classes.eventsContainer}>
        {events.map(e => {
  const difference = moment(e.slutt).diff(e.start, "days")
     return(
          <React.Fragment>
            <Box mb={4} mt={4}>
              <Grid
                container
                key={e.id}
                spacing={3}
                onMouseEnter={() => onHover(e.id)}
                onMouseLeave={() => onHover(false)}
                onClick={() => navigate(`/arrangement/${e.slug}`)}
                className={hovered === e.id ? classes.hovered : ""}
              >
                <Grid item xs="12" md="4">
                  <OverlayImage
                    image={e.cover}
                    backupImage={backupEventImg}
                    text={e.beskrivelse}
                    date={difference > 0 ? `${moment(e.start).format("D")}-${moment(e.slutt).format("D MMMM YYYY")}` : moment(e.start).format("dddd, D MMMM YYYY")}
                    multiDate={difference > 0}
                    onHover={onHover}
                    hovered={hovered}
                  />
                </Grid>
                <Grid item xs="12" md="8">
                  <Text variant="subheader">
                    <Badge color="black">{e.campus}</Badge>
                    {e.tags && <GenerateTags tags={e.tags} color="black" />}
                  </Text>
                  <div>
                    <Title
                      component={Link}
                      to={`/arrangement/${e.slug}`}
                      variant="h4"
                      color="secondary"
                    >
                      {e.tittel}
                    </Title>
                  </div>
                  <EventList
                    horizontal
                    date={difference > 0 ? `${moment(e.start).format("D")}-${moment(e.slutt).format("D MMMM YYYY")}` : moment(e.start).format("dddd, D MMMM YYYY")}
                    time={`${moment(e.start).format("HH:mm")}-${moment(e.slutt).format("HH:mm")}`}
                    location={e.adresse}
                    food={e.mat}
                    maxAttendees={
                      e.maksdeltakere ? `Maks ${e.maksdeltakere} stk` : ""
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
          </React.Fragment>
        )})}
        {events.length < 1 && (
          <Container align="center" maxWidth="sm">
            <Box pt={8}>
              <Text black variant="h5" component="p">
                Det er dessverre ikke noen arrangement i {filter.campus} for
                tiden. For mer info hva som skjer:
              </Text>
              <Button
                color="primary"
                onClick={() => navigate("/om-oss#kontakt-oss")}
              >
                Ta kontakt
              </Button>
            </Box>
          </Container>
        )}
      </Container>

      
    </Layout>
  )
}

export const ItemPageQuery = graphql`
  query Events($now: Date) {
    allArrangement(
      sort: { order: ASC, fields: start }
      filter: { start: { gte: $now } }
    ) {
      nodes {
        start
        slutt
        slug
        registreringslenke
        programUrl
        mat
        maksdeltakere
        facebookevent
        cover
        id
        campus
        beskrivelse
        adresse
        tittel
        tags
      }
    }
    eventImg: file(relativePath: { eq: "event_cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default EventsPage
